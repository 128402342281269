import { Box, Flex, Text } from '@chakra-ui/react';
import { IconButton, SelectOption, SvgSpriteConsumer } from '@pluxee-design-system/core';
import { useCallback, useEffect, useRef, useState } from 'react';

const hasOverflow = (textContainer: HTMLDivElement) =>
  textContainer.offsetHeight < textContainer.scrollHeight ||
  textContainer.offsetWidth < textContainer.scrollWidth;

// TODO: export to generic component?
const ServiceDetail = ({ detail, description, label }: SelectOption) => {
  const descriptionRef = useRef<HTMLDivElement | null>(null);
  const [isExpandable, setExpandable] = useState(Boolean(detail));
  const [isExpanded, setExpanded] = useState(false);
  const toggleExpanded = useCallback(() => setExpanded((p) => !p), [setExpanded]);
  const setDescriptionRef = useCallback(
    (textContainer: HTMLDivElement) => {
      if (textContainer && !detail) {
        setExpandable(hasOverflow(textContainer));
      }
      descriptionRef.current = textContainer;
    },
    [detail, setExpandable],
  );

  useEffect(() => {
    if (descriptionRef.current && !detail) {
      setExpandable(hasOverflow(descriptionRef.current));
    }
  }, [description, detail]);

  return (
    <Flex gap={4} justify="space-between" width="100%">
      <Box>
        <Text variant="subtitle.section">{label}</Text>
        <Text
          as="div"
          variant="subtitle.card"
          mt={1.5}
          noOfLines={isExpanded ? undefined : 2}
          ref={setDescriptionRef}
        >
          {description}
        </Text>
      </Box>
      {isExpandable && (
        <Box>
          <IconButton
            aria-label="chevron"
            icon={
              <SvgSpriteConsumer size={24} svgId={isExpanded ? 'chevronUp24' : 'chevronDown24'} />
            }
            onClick={toggleExpanded}
            size="md"
            variant="icon"
          />
        </Box>
      )}
    </Flex>
  );
};

export default ServiceDetail;
