import useModal from 'context/ModalContext';
import useTranslations from 'i18n';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { unsavedChangesModalParams } from './modal';
import { simplifyUrl } from './utils';

interface NextRouteType {
  url: string | null;
  shouldWarn: boolean;
}

interface WarningOnExitProps {
  onUnmount?: () => void;
  unsavedChanges: boolean;
}

const WarningOnExit = ({ onUnmount, unsavedChanges }: WarningOnExitProps) => {
  const { t } = useTranslations();
  const router = useRouter();
  const showModal = useModal();
  const [nextRoute, setNextRoute] = useState<NextRouteType>({ url: null, shouldWarn: false });

  useEffect(() => {
    const message = t(
      'global_texts.modals.unsavedChanges.description',
      'Your changes will be lost. Do you want to proceed?',
    );

    // Event handlers
    const routeChangeStart = (urlWithBase: string) => {
      if (urlWithBase.includes('ignoreWarnOnExit')) return;
      const url = simplifyUrl(urlWithBase, router);
      const oldUrl = router.asPath;
      if (oldUrl !== url && unsavedChanges && !nextRoute.shouldWarn) {
        setNextRoute({ url, shouldWarn: true });
        // don't change current url
        router.replace(oldUrl, oldUrl, { shallow: true });
        // to abort next/router change, see https://github.com/vercel/next.js/issues/2476
        router.events.emit('routeChangeError');
        throw 'Abort route change. Please ignore this error.';
      }
    };

    const beforeUnload = (e: BeforeUnloadEvent) => {
      if (unsavedChanges && !nextRoute.shouldWarn) {
        const event = e || window.event;
        event.returnValue = message;
        return message;
      }
      return null;
    };

    if (unsavedChanges && nextRoute.shouldWarn) {
      showModal(unsavedChangesModalParams(t)).then((value) => {
        if (value && nextRoute.url !== null) {
          router.push(nextRoute.url);
          return;
        }
        setNextRoute({ url: nextRoute.url, shouldWarn: false });
      });
    }

    // Event listeners
    router.events.on('routeChangeStart', routeChangeStart);
    window.addEventListener('beforeunload', beforeUnload);
    router.beforePopState(({ url: urlWithBase }) => {
      const url = simplifyUrl(urlWithBase, router);
      const oldUrl = router.asPath;
      if (oldUrl !== url && unsavedChanges && !nextRoute.shouldWarn) {
        setNextRoute({ url, shouldWarn: true });
        window.history.pushState(null, '', url);
        router.replace(oldUrl, oldUrl, { shallow: true });
        return false;
      }
      return true;
    });

    if (!unsavedChanges && onUnmount) {
      onUnmount();
    }

    // Clean-up event listeners on component unmount
    return () => {
      router.events.off('routeChangeStart', routeChangeStart);
      window.removeEventListener('beforeunload', beforeUnload);
      router.beforePopState(() => true);
    };
  }, [
    nextRoute.shouldWarn,
    nextRoute.url,
    router,
    setNextRoute,
    showModal,
    onUnmount,
    unsavedChanges,
    t,
  ]);

  return <></>;
};

export default WarningOnExit;
