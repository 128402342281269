// https://www.figma.com/file/mrdiByIw3khIMAh05RjAcL/002---PLX-UI-Library?node-id=35285%3A37466&mode=dev
import { Flex } from '@chakra-ui/react';
import { px, py } from 'common/layouts/layout.styles';
import { ReactNode } from 'react';

interface OneColumnProps {
  children: ReactNode;
}

const OneColumn = ({ children }: OneColumnProps) => (
  <Flex flex="1 1 0%" align="stretch" gap={10} direction="column" py={py} px={px} minWidth={0}>
    {children}
  </Flex>
);

export default OneColumn;
