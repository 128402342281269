import { useBanks } from 'api/defaultApi';
import SelectControl, { SelectControlProps } from 'common/forms/SelectControl';
import { Bank, BankAccount, BankListResponse } from 'generated';
import withData from 'HOC/withData';

export const getLabelFromBank = (item: Bank) => `${item.name}${item.code ? ` (${item.code})` : ''}`;
export const getLabelFromBankAccount = (item: BankAccount) =>
  `${item.bankName ?? item.bankId}${item.bankCode ? ` (${item.bankCode})` : ''}`;

const BankSelect = withData<BankListResponse, Bank[], any, Omit<SelectControlProps, 'options'>>(
  SelectControl,
  {
    propsKey: 'options',
    selector: (response) =>
      response.entries.map((item) => ({
        key: item.id,
        value: item.id,
        label: getLabelFromBank(item),
      })),
    useFetcher: useBanks,
  },
);

export default BankSelect;
