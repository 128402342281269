import { deburr, toLower } from 'lodash';
import {
  Location, LocationItem,
  LocationSoft,
  ProductLocationsSortingEnum,
} from 'generated/models';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { LocationType, ProductLocationFilterValues } from 'components/Form/LocationSelect/types';

const normalizeValue = (str: string) => toLower(deburr(str));

export const isLocationItem = (obj: LocationType): obj is LocationItem => {
  return Boolean((obj as LocationItem)?.location);
};

export const getLabel = (loc: LocationType) => {
  if (isLocationItem(loc)) {
    return (loc?.location?.name || loc?.location?.masterId)!;
  }
  return (loc?.name || loc?.masterId)!;
};

export const getValue = (loc: LocationType) => (isLocationItem(loc) ? loc?.location?.uid : loc.uid);

export const id = (_option: FilterOptionOption<LocationType>, _inputValue: string) => true;


export const filterOption = (
  { data }: FilterOptionOption<Location | LocationSoft>,
  rawInput: string,
): boolean => {
  const input = normalizeValue(rawInput);
  const toTest = [
    data.name,
    data.address?.street,
    data.address?.houseNumber,
    data.address?.zip,
    data.address?.city,
    data.masterId,
    data.merchantLocationId,
    data.uid,
  ];
  return toTest.some((v) => v && normalizeValue(v).includes(input));
};

export const initialValues: ProductLocationFilterValues = {
  // approvedOnly: false,
  // inactive: false,
  // fulltext: '',
  // states: [],
  // withPaperProduct: false,

  sortBy: ProductLocationsSortingEnum.Name,
  sortOrder: 'desc',
};
