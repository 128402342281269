import { Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface GridLayoutProps {
  children: ReactNode;
}

const GridLayout = ({ children }: GridLayoutProps) => (
  <Flex gap={6} direction="column" minHeight="150px">
    {children}
  </Flex>
);

export default GridLayout;
