import { SpaceProps, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface TextTitleProps extends Pick<SpaceProps, 'marginBottom' | 'mb' | 'marginTop' | 'mt'> {
  title: ReactNode;
  subtitle?: ReactNode;
}

const TextTitle = ({ title, subtitle, marginBottom, mb, marginTop, mt }: TextTitleProps) => (
  <>
    <Text
      as="h1"
      variant="title.desktopMain"
      mb={subtitle ? 2 : marginBottom ?? mb}
      mt={marginTop ?? mt}
    >
      {title}
    </Text>
    {subtitle && (
      <Text
        as="h2"
        variant="subtitle.desktopMain"
        color="semantic.text.tertiary"
        mb={marginBottom ?? mb}
      >
        {subtitle}
      </Text>
    )}
  </>
);

export default TextTitle;
