import { Flex, SpaceProps, Spacer, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface SectionTitleProps extends Pick<SpaceProps, 'marginBottom' | 'mb' | 'marginTop' | 'mt'> {
  title: ReactNode;
  description?: ReactNode;
  children?: ReactNode;
}

const afterStyle = {
  content: `""`,
  width: '0%',
  height: '0px',
  borderTopColor: 'transparent',
  borderRightColor: 'transparent',
  borderBottomColor: 'transparent',
  borderLeftColor: 'semantic.brand.tertiary',
  position: 'absolute',
  top: 'calc(50% - 0.75rem)', // 3
  left: '0',
  borderStyle: 'solid',
  borderWidth: '0.75rem 0 0.75rem 0.75rem',
};

const SectionTitle = ({
  description,
  children,
  marginBottom,
  mb,
  marginTop,
  mt,
  title,
}: SectionTitleProps) => (
  <>
    {children ? (
      <Flex
        align="center"
        gap={2}
        background="semantic.brand.tertiary-dimmed"
        position="relative"
        mb={marginBottom ?? mb}
        mt={marginTop ?? mt}
        pl={7} // 4 + 3 units (1rem + 0.75rem -> 1.75rem -> 7 units)
        pr={4}
        py={2}
        _after={afterStyle}
      >
        <Text as="h3" variant="title.desktopSection">
          {title}
        </Text>
        <Spacer />
        {children}
      </Flex>
    ) : (
      <Text
        as="h3"
        variant="title.desktopSection"
        background="semantic.brand.tertiary-dimmed"
        mb={marginBottom ?? mb}
        mt={marginTop ?? mt}
        pl={7} // 4 + 3 units (1rem + 0.75rem -> 1.75rem -> 7 units)
        pr={4}
        py={2}
        position="relative"
        _after={afterStyle}
      >
        {title}
      </Text>
    )}
    {description && (
      <Text variant="subtitle.section" color="semantic.text.tertiary">
        {description}
      </Text>
    )}
  </>
);

export default SectionTitle;
