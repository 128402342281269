import { Box, Flex, SpaceProps, Spacer, Text } from '@chakra-ui/react';
import { LayoutProps } from '@chakra-ui/styled-system';
import { ReactNode } from 'react';

interface PageTitleProps extends Pick<SpaceProps, 'marginBottom' | 'mb' | 'marginTop' | 'mt'> {
  title: ReactNode;
  subtitle?: ReactNode;
  top?: ReactNode;
  children?: ReactNode;
  withoutPr?: boolean;
  subtitleHideBelow?: LayoutProps['hideBelow'];
  subtitleHideFrom?: LayoutProps['hideFrom'];
}

const PageTitle = ({
  children,
  title,
  top,
  subtitle,
  subtitleHideBelow,
  subtitleHideFrom,
  marginBottom,
  mb,
  marginTop,
  mt,
  withoutPr,
}: PageTitleProps) => (
  <Box>
    {top}
    {children ? (
      <Flex
        align={{ base: 'flex-start', md: 'center' }}
        gap={4}
        pr={withoutPr ? undefined : 4}
        mb={subtitle ? 2 : marginBottom ?? mb}
        mt={marginTop ?? mt}
        direction={{ base: 'column', md: 'row' }}
      >
        <Text as="h1" variant="title.desktopPage" color="semantic.text.tertiary">
          {title}
        </Text>
        <Spacer />
        {children}
      </Flex>
    ) : (
      <Text
        as="h1"
        variant="title.desktopPage"
        color="semantic.text.tertiary"
        mb={subtitle ? 2 : marginBottom ?? mb}
        mt={marginTop ?? mt}
      >
        {title}
      </Text>
    )}
    {subtitle && (
      <Text
        as="h2"
        variant="subtitle.desktopMain"
        mb={marginBottom ?? mb}
        hideFrom={subtitleHideFrom}
        hideBelow={subtitleHideBelow}
      >
        {subtitle}
      </Text>
    )}
  </Box>
);

export default PageTitle;
