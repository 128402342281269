import { AxiosError } from 'axios';
import { useUserInfo } from 'api/userApi';
import { CompanyPosition } from 'generated/models';

interface ReturnType {
  error?: AxiosError;
  isOK: boolean;
  isBOUser: boolean;
  companyPosition?: CompanyPosition;
}

const useCurrentUser = (contractUid?: string): ReturnType => {
  const { error, data, isOK } = useUserInfo();

  return {
    error,
    isOK,
    isBOUser: !!data?.boUser,
    companyPosition: data?.employees?.find((e) => e.contractUid === contractUid)?.companyPosition,
  };
};

export default useCurrentUser;
