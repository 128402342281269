// TODO: move to utils
export const mapIdToKey = <E extends { id?: string | number; name?: string }>(items: E[]) =>
  items.map((item) => ({ key: item.id, name: item.name, value: item.id, label: item.name }));

// TODO: move to utils
export const mapGroupToKey = (items: Record<string, any>[]) =>
  Object.entries(items).map(([key, name]) => ({
    key, // TODO: remove
    name: name as unknown as string, // TODO: remove
    value: key,
    label: name as unknown as string,
  }));
