import CheckboxGroupControl, { CheckboxGroupControlProps } from 'common/forms/CheckboxGroupControl';
import SelectControl, { SelectControlProps } from 'common/forms/SelectControl';
import { useMemo } from 'react';
import ServiceDetail from './ServiceDetail';

type CommonProps = SelectControlProps | CheckboxGroupControlProps;

export type ServiceListProps = CommonProps & {
  onlyAllowed?: string[];
  variant?: 'multiple' | 'single';
  notFoundText?: string;
  isLoading?: boolean;
};

const ServiceList = ({
  variant = 'multiple',
  isLoading,
  onlyAllowed = [],
  options,
  ...props
}: ServiceListProps) => {
  const filteredOptions = useMemo(() => {
    if (onlyAllowed && onlyAllowed?.length > 0) {
      return options.filter((option) => onlyAllowed?.includes(option.value));
    }
    return options;
  }, [onlyAllowed, options]);

  return variant === 'multiple' ? (
    <CheckboxGroupControl options={filteredOptions} {...props} renderComponent={ServiceDetail} />
  ) : (
    <SelectControl isLoading={isLoading} options={filteredOptions} {...props} isArrayValue />
  );
};

export default ServiceList;
