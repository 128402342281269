import { ResponseType } from 'api/useRequest';
import useConfig from 'data/useConfig';
import { I18nProps, TranslateFn, TranslateHtmlFn, withLazyNamespaces } from 'i18n';
import { ComponentType, useMemo } from 'react';
import { CountryCode } from 'types/config';

// TODO: handle propsKey typecheck (propsKey: keyof P), return P[propsKey]
interface ResourceDescriptor<R = any, D = any, E = any> {
  propsKey: string;
  defaultData?: D;
  selector?: (
    response: R,
    t: TranslateFn,
    tHtml: TranslateHtmlFn,
    isoCode: CountryCode,
  ) => Record<string, any>;
  useFetcher: () => Partial<ResponseType<R, E>>;
  namespace?: string;
}

/**
 * Gets a translation and injects the translation function to a wrapped component.
 *
 * @param Component Component to be injected with the translation function.
 * @param resource List of namespaces to be fetched from API.
 */
const withData = <R extends unknown, D = any, E = any, P = any>(
  Component: ComponentType<P>,
  resource: ResourceDescriptor<R, D, E>,
): ComponentType<P> =>
  // @ts-ignore
  withLazyNamespaces(
    function WithData(props: P & I18nProps) {
      const { t, tHtml, nsReady } = props;
      const { isoCode } = useConfig();
      const { propsKey, useFetcher, defaultData, selector } = resource;
      const { data, isOK, isLoading } = useFetcher();
      const isLoadingNamespace = resource?.namespace
        ? !nsReady(isoCode, resource.namespace)
        : false;

      const dataProps = useMemo(
        () => ({
          [propsKey]: selector && isOK && data ? selector(data, t, tHtml, isoCode) : defaultData,
        }),
        [propsKey, selector, isoCode, isOK, data, t, tHtml, defaultData],
      );

      return (
        <Component
          {...props}
          {...dataProps}
          isLoading={isLoading || isLoadingNamespace}
          loadingText={t('global_texts.labels.loading_data', undefined)}
        />
      );
    },
    ...(resource.namespace ? [resource.namespace] : []),
  );

export default withData;
