import { Flex, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface FeeRowProps {
  name: ReactNode;
  children: ReactNode;
}

const FeeRow = ({ children, name }: FeeRowProps) => (
  <Flex gap={4} justify="space-between" width="100%">
    <Text as="div" variant="subtitle.card">
      {name}
    </Text>
    <Text as="div" variant="subtitle.card" color="semantic.text.highlight">
      {children}
    </Text>
  </Flex>
);

export default FeeRow;
