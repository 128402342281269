export interface SessionUserType {
  id: string;
  name: string;
  email: string;
  firstName: string;
  lastName: string;
  locale: string;
  telephone: string;
}

export interface Session {
  accessToken?: string;
  accessTokenExpiry?: number;
  idToken?: string;
  user?: SessionUserType;
  error?: 'RefreshAccessTokenError';
}

export interface SignInOptions {
  callbackUrl?: string;
  selectAccount?: boolean;
  createAccount?: boolean;
}

export type EnumDict<T extends string | symbol | number, U> = {
  [K in T]: U;
};

export enum AffiliationStatusEnum {
  Loading, // A request to backend is being sent
  Error, // An error occurred while fetching user data
  NotLogged, // A user is not logged in
  NotAffiliated, // A user has not started even finished a company affiliation
  AffiliationDraft, // A user has not completely finished a company affiliation, but entered some information
  Onboarding, // A user has already affiliated a company, but not finished onboarding
  OnboardingDraft, // A user has not completely finished onboarding, but entered some information
  Affiliated, // A user has already affiliated a company and finished onboarding
  Unconfirmed, // A backoffice user has affiliated a company, but a regular user hasn't accepted conditions
}

export enum InvitationStateEnum {
  Loading, // A request to backend is being sent
  NotLoggedIn, // Not logged in with SodexoConnect (SDXC) when opened activation link
  LoggedIn, // Already logged in with SDXC when opened activation link
  SelectedAccount, // Selected SDXC account to continue with
  HasAccess, // SDXC account already assigned to a contract
  TokenExpired,
  TokenInvalid,
  Completed, // All conditions met
}

export enum AffiliationStepEnum {
  CompanyId = 'companyId',
  CompanyInfo = 'companyInfo',
  ContactInfo = 'contactInfo',
  ProductSelection = 'productSelection',
  PaymentInfo = 'paymentInfo',
  ContractConfirmation = 'contractConfirmation',
}

export type OptionalUnion<T1, T2> = {
  [P in keyof Omit<T1 & T2, keyof (T1 | T2)>]?: (T1 & T2)[P];
} & (T1 | T2);
