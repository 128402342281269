import { TranslateFn } from 'i18n';
import { ShowModalParams } from 'context/ModalContext';

export const unsavedChangesModalParams = (t: TranslateFn): ShowModalParams => ({
  confirmText: t('global_texts.modals.unsavedChanges.confirm', 'Leave page'),
  cancelText: t('global_texts.modals.unsavedChanges.cancel', 'Cancel'),
  title: t('global_texts.modals.unsavedChanges.header', 'Unsaved changes'),
  text: t(
    'global_texts.modals.unsavedChanges.description',
    'You are about to leave this page. Your changes will be lost. Do you want to proceed?',
  ),
});
