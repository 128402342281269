import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { debounce, isEqual } from 'lodash';

interface AutoSubmitProps {
  waitTime?: number;
}

const AutoSubmit = ({ waitTime = 300 }: AutoSubmitProps) => {
  const { submitForm, initialValues, values } = useFormikContext();

  const debouncedSubmit = useMemo(
    () => debounce(() => submitForm(), waitTime),
    [submitForm, waitTime],
  );

  useEffect(() => {
    if (!isEqual(initialValues, values)) {
      debouncedSubmit();
    }
  }, [debouncedSubmit, initialValues, values]);

  return <></>;
};

export default AutoSubmit;
